html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-weight: 300;
  background-color: #ffffff;
  background: linear-gradient(to bottom, #111, #111 80px, #fff 80px, #fff);
  width: 100%;
  height: 100%;
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@media (min-width: 568px) {
  body {
    font-size: 18px;
    line-height: 28px;
    min-width: 568px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 764px) {
  body {
    min-width: 764px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 940px) {
  body {
    font-size: 20px;
    line-height: 30px;
    min-width: 940px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1180px) {
  body {
    min-width: 1180px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: clip;
  }
}

.overflow-hidden {
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.login__img {
  width: 30px;
  height: 30px;
  fill: #ffffff;
  overflow: visible;
}
@media (min-width: 940px) {
  .login__img {
    fill: #ffffff;
  }
}

@media (min-width: 940px) {
  .login__img--alternative {
    fill: #ffffff;
  }
}

.hr {
  width: 160px;
  margin: 0 auto;
  height: 2px;
  background-color: #ffffff;
}

.container {
  width: 100%;
  margin-inline-start: 8px;
  margin-inline-end: 8px;
  display: flex;
}
@media (min-width: 568px) {
  .container {
    width: 546px;
    margin-inline-start: 30px;
    margin-inline-end: 30px;
  }
}
@media (min-width: 764px) {
  .container {
    width: 704px;
  }
}
@media (min-width: 940px) {
  .container {
    width: 910px;
  }
}
@media (min-width: 1180px) {
  .container {
    width: 1120px;
  }
}

.container--catalog {
  flex-direction: column;
}

.timer {
  position: absolute;
  width: 80px;
  height: 30px;
  background-color: #ffffff;
  top: 170px;
  right: 10px;
  border-radius: 15px;
  color: #ffffff;
  font-size: 15px;
  padding-inline-start: 30px;
  padding-block-start: 1px;
  background-image: url("../img/icon-2-fordev-time5.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 5px 5px;
}
@media (min-width: 568px) {
  .timer {
    right: auto;
    left: 167px;
  }
}
@media (min-width: 764px) {
  .timer {
    right: 10px;
    left: auto;
  }
}
@media (min-width: 1180px) {
  .timer {
    top: 215px;
  }
}

.timer--long {
  width: 90px;
  background-image: url("../img/icon-2-fordev-time55.svg");
}
@media (min-width: 568px) {
  .timer--long {
    left: 157px;
  }
}

.debug-illumination--red {
  outline: 1px solid red !important;
  background-color: rgba(255, 0, 0, 0.3) !important;
}

.debug-illumination--blue {
  outline: 1px solid blue !important;
  background-color: rgba(0, 17, 255, 0.3) !important;
}

.debug-illumination--green {
  outline: 1px solid green !important;
  background-color: rgba(0, 128, 0, 0.3) !important;
}

.heading {
  font-weight: 300;
  width: 100%;
  text-align: center;
  padding-block-end: 10px;
  margin-block-start: 0;
  margin-block-end: 0;
}
@media (min-width: 940px) {
  .heading {
    max-width: 740px;
    padding-block-end: 20px;
  }
}

.heading--h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
}
@media (min-width: 568px) {
  .heading--h1 {
    font-size: 40px;
    line-height: 56px;
  }
}
@media (min-width: 940px) {
  .heading--h1 {
    font-size: 46px;
    line-height: 64px;
  }
}

.heading--h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
}
@media (min-width: 568px) {
  .heading--h2 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (min-width: 940px) {
  .heading--h2 {
    font-size: 36px;
    line-height: 44px;
  }
}

.heading--h3 {
  font-size: 26px;
  line-height: 36px;
}
@media (min-width: 568px) {
  .heading--h3 {
    font-size: 26px;
    line-height: 36px;
    padding-block-end: 10px;
  }
}
@media (min-width: 940px) {
  .heading--h3 {
    font-size: 30px;
    line-height: 40px;
    padding-block-end: 10px;
  }
}

.heading--h4 {
  font-size: 21px;
  line-height: 31px;
  font-weight: 400;
}
@media (min-width: 568px) {
  .heading--h4 {
    font-size: 21px;
    line-height: 31px;
  }
}
@media (min-width: 940px) {
  .heading--h4 {
    font-size: 24px;
    line-height: 34px;
  }
}

.heading--h5 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}
@media (min-width: 568px) {
  .heading--h5 {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (min-width: 940px) {
  .heading--h5 {
    font-size: 20px;
    line-height: 30px;
  }
}

.heading--h6 {
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 568px) {
  .heading--h6 {
    font-size: 15px;
    line-height: 25px;
  }
}
@media (min-width: 940px) {
  .heading--h6 {
    font-size: 16px;
    line-height: 26px;
  }
}

.heading--paddingBottom-0 {
  padding-block-end: 0px;
}
@media (min-width: 764px) {
  .heading--paddingBottom-0 {
    padding-block-end: 0px;
  }
}
@media (min-width: 940px) {
  .heading--paddingBottom-0 {
    padding-block-end: 0px;
  }
}

.heading--paddingBottom-5 {
  padding-block-end: 5px;
}
@media (min-width: 940px) {
  .heading--paddingBottom-5 {
    padding-block-end: 5px;
  }
}

.heading--paddingBottom-10 {
  padding-block-end: 10px;
}
@media (min-width: 940px) {
  .heading--paddingBottom-10 {
    padding-block-end: 10px;
  }
}

.heading--paddingBottom-20 {
  padding-block-end: 20px;
}
@media (min-width: 940px) {
  .heading--paddingBottom-20 {
    padding-block-end: 20px;
  }
}

.heading--paddingTop-15 {
  padding-block-start: 15px;
}
@media (min-width: 940px) {
  .heading--paddingTop-15 {
    padding-block-start: 15px;
  }
}

.heading--paddingTop-30 {
  padding-block-start: 30px;
}
@media (min-width: 940px) {
  .heading--paddingTop-30 {
    padding-block-start: 30px;
  }
}

.heading--paddingTop-50 {
  padding-block-start: 30px;
}
@media (min-width: 940px) {
  .heading--paddingTop-50 {
    padding-block-start: 50px;
  }
}

.heading--paddingBottom-30 {
  padding-block-end: 30px;
}
@media (min-width: 940px) {
  .heading--paddingBottom-30 {
    padding-block-end: 30px;
  }
}

.heading--paddingBottom-50 {
  padding-block-end: 30px;
}
@media (min-width: 764px) {
  .heading--paddingBottom-50 {
    padding-block-end: 44px;
  }
}
@media (min-width: 940px) {
  .heading--paddingBottom-50 {
    padding-block-end: 50px;
  }
}

.heading--link {
  text-decoration: none;
  color: inherit;
}

.heading--center {
  margin: 0 auto;
}

.heading--left {
  text-align: left;
}

@media (min-width: 764px) {
  .heading--left-mobile {
    text-align: left;
  }
}

.heading__underline {
  position: relative;
}

.heading__underline::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 5px;
  left: 0;
  background-color: #333333;
}

.heading--uppercase {
  text-transform: uppercase;
}

.heading--white {
  color: #ffffff;
}

.heading--bold {
  font-weight: bold;
}

/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("https://fonts.gstatic.com/s/roboto/v18/7m8l7TlFO-S3VkhHuR0atyYE0-AqJ3nfInTTiDXDjU4.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("https://fonts.gstatic.com/s/roboto/v18/7m8l7TlFO-S3VkhHuR0at44P5ICox8Kq3LLUNMylGO4.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"), url("https://fonts.gstatic.com/s/roboto/v18/vSzulfKSK0LLjjfeaxcREhTbgVql8nDJpwnrE27mub0.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"), url("https://fonts.gstatic.com/s/roboto/v18/vPcynSL0qHq_6dX7lKVByfesZW2xOQ-xsNqO47m55DA.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("https://fonts.gstatic.com/s/roboto/v18/t6Nd4cfPRhZP44Q5QAjcCyYE0-AqJ3nfInTTiDXDjU4.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("https://fonts.gstatic.com/s/roboto/v18/t6Nd4cfPRhZP44Q5QAjcC44P5ICox8Kq3LLUNMylGO4.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("https://fonts.gstatic.com/s/roboto/v18/Pru33qjShpZSmG3z6VYwnRJtnKITppOI_IvcXXDNrsc.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("https://fonts.gstatic.com/s/roboto/v18/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("https://fonts.gstatic.com/s/roboto/v18/Fcx7Wwv8OzT71A3E1XOAjvesZW2xOQ-xsNqO47m55DA.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("https://fonts.gstatic.com/s/roboto/v18/CWB0XYA8bzo0kSThX0UTuA.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("https://fonts.gstatic.com/s/roboto/v18/97uahxiqZRoncBaCEI3aWxJtnKITppOI_IvcXXDNrsc.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("https://fonts.gstatic.com/s/roboto/v18/d-6IYplOFocCacKzxwXSOFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap;
}
.text {
  width: 100%;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-block-start: 5px;
  padding-block-end: 5px;
}
@media (min-width: 568px) {
  .text {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (min-width: 940px) {
  .text {
    max-width: 740px;
    font-size: 20px;
    line-height: 30px;
  }
}

.text--regular {
  font-weight: 400;
}

.text--bold {
  font-weight: bold;
}

@media (min-width: 568px) {
  .text--center-mobile {
    text-align: left;
  }
}

.text--left {
  text-align: left;
}

@media (min-width: 764px) {
  .text--left-mobile {
    text-align: left;
  }
}

@media (min-width: 940px) {
  .text--promo {
    text-align: left;
  }
}

.text--sm {
  font-size: 15px;
  line-height: 25px;
  padding-block-start: 5px;
  padding-block-end: 5px;
  text-align: left;
}
@media (min-width: 568px) {
  .text--sm {
    font-size: 15px;
    line-height: 25px;
  }
}
@media (min-width: 940px) {
  .text--sm {
    font-size: 16px;
    line-height: 26px;
    max-width: 910px;
  }
}

.text--sm-center {
  text-align: center;
}

@media (min-width: 568px) {
  .text--narrow {
    max-width: 614px;
    margin: auto;
  }
}
@media (min-width: 940px) {
  .text--narrow {
    max-width: 700px;
  }
}

.text--code {
  background-color: #00000040;
  margin-block-start: 10px;
  margin-block-end: 10px;
  padding-block-start: 15px;
  padding-block-end: 15px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  border-radius: 10px;
  font-family: Consolas, Menlo, Monaco, monospace;
  font-size: 15px;
  line-height: 25px;
  overflow-x: auto;
}
@media (min-width: 568px) {
  .text--code {
    font-size: 15px;
    line-height: 25px;
  }
}
@media (min-width: 940px) {
  .text--code {
    font-size: 16px;
    line-height: 26px;
  }
}

.text--grey {
  color: #888888;
}

.text--dark {
  color: #333333;
}

.text--nopadding {
  padding: 0;
}

.text--padding-bottomBig {
  padding-block-end: 20px;
}
@media (min-width: 568px) {
  .text--padding-bottomBig {
    padding-block-end: 25px;
  }
}
@media (min-width: 940px) {
  .text--padding-bottomBig {
    padding-block-end: 25px;
  }
}

.text--padding-topBig {
  padding-block-start: 20px;
}
@media (min-width: 568px) {
  .text--padding-topBig {
    padding-block-start: 25px;
  }
}
@media (min-width: 940px) {
  .text--padding-topBig {
    padding-block-start: 25px;
  }
}

.text--uppercase {
  text-transform: uppercase;
}

.text--italic {
  font-style: italic;
}

.text--white {
  color: #ffffff;
}

.text--transparent {
  opacity: 0.5;
}

.text__link {
  outline: none;
  text-decoration: none;
  color: #333333;
  background-image: linear-gradient(to right, #88888888 100%, #88888888 100%);
  background-position: 0 1.05em;
  background-repeat: repeat-x;
  background-size: 8px 1px;
  cursor: pointer;
  padding-block-end: 0;
}
.text__link:visited {
  color: #888888;
  background-image: linear-gradient(to right, #00000040 100%, #00000040 100%);
}
.text__link:hover {
  color: #000000;
  background-image: linear-gradient(to right, #888888 100%, #888888 100%);
}
.text__link:focus, .text__link:active {
  color: #000000;
  background-image: linear-gradient(to right, #00000040 100%, #00000040 100%);
}

.text__link--second {
  margin-inline-start: 30px;
}

.text__link--alt {
  color: #ffffff;
  background-image: linear-gradient(to right, #ffffff88 100%, #ffffff88 100%);
}
.text__link--alt:visited {
  color: #ffffff90;
  background-image: linear-gradient(to right, #ffffffcc 100%, #ffffffcc 100%);
}
.text__link--alt:hover {
  color: #ffffff;
  background-image: linear-gradient(to right, #ffffff 100%, #ffffff 100%);
}
.text__link--alt:focus, .text__link--alt:active {
  color: #ffffff90;
  background-image: linear-gradient(to right, #ffffffcc 100%, #ffffffcc 100%);
}

.text__link--details {
  position: relative;
}

.text__nowrap {
  white-space: nowrap;
}

.text__sup {
  font-size: 0.5em;
}

.pageHeader {
  background-color: #00000040;
  position: fixed;
  width: 100vw;
  z-index: 10;
}
@media (min-width: 940px) {
  .pageHeader {
    display: flex;
    background-color: #000000;
    width: 100%;
  }
}

.pageHeader__logoWrapper {
  background-color: #000000;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 5px;
  padding-inline-end: 15px;
}
@media (min-width: 940px) {
  .pageHeader__logoWrapper {
    display: none;
  }
}

.pageHeader__logoLink {
  justify-self: center;
  margin-inline-end: 30px;
}

.pageHeader__contacts {
  display: grid;
  grid-gap: 10px;
  height: fit-content;
  padding-inline-end: 15px;
  padding-inline-start: 15px;
  padding-block-end: 15px;
  padding-block-start: 15px;
}

.pageHeader__phone {
  border-radius: 7px;
  border: 1px solid #ffffff;
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  width: fit-content;
  height: 40px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  display: grid;
  justify-content: center;
  align-items: center;
}
@media (min-width: 940px) {
  .pageHeader__phone {
    border: none;
    padding-inline-end: 0;
    padding-inline-start: 0;
    height: 20px;
  }
}

.pageHeader__phone:hover,
.pageHeader__phone:focus {
  color: #ffffffcc;
}

.burger {
  width: 40px;
  height: 40px;
  content: "";
  display: grid;
  align-content: center;
  justify-content: center;
}

.burger__element {
  width: 20px;
  height: 2px;
  content: "";
  background-color: white;
  position: relative;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.burger__element::before,
.burger__element::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  content: "";
  background-color: white;
  top: -7px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.burger__element::after {
  top: 7px;
}

.burger__element--close {
  transform: rotate(45deg);
}

.burger__element--close::before {
  transform: rotate(90deg);
  top: 0;
}

.burger__element--close::after {
  opacity: 0;
}

.mainNav {
  display: none;
  flex-direction: column;
  min-height: calc(100vh - 75px);
  padding-block-end: 120px;
  width: 210px;
  background-color: #000000;
}
@media (min-width: 940px) {
  .mainNav {
    display: flex;
    flex-direction: row;
    min-height: 80px;
    width: 100%;
    max-width: 910px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    padding-block-end: 0;
  }
}
@media (min-width: 1180px) {
  .mainNav {
    max-width: 1120px;
  }
}

.mainNav--open {
  display: flex;
}

.mainNav__list {
  list-style: none;
  padding-inline-start: 15px;
  margin-block-end: auto;
}
@media (min-width: 940px) {
  .mainNav__list {
    display: flex;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-end: auto;
  }
}
@media (min-width: 1180px) {
  .mainNav__list {
    max-width: calc(50% + 75px);
    width: 100%;
  }
}

.mainNav__link {
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  padding-block-end: 5px;
  padding-block-start: 5px;
  display: block;
}
@media (min-width: 940px) {
  .mainNav__link {
    font-size: 15px;
    line-height: 25px;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-inline-end: 7px;
    padding-inline-start: 7px;
  }
}

.mainNav__link:hover,
.mainNav__link:focus {
  color: #ffffffcc;
}

.mainNav__item--logo {
  display: none;
}
@media (min-width: 940px) {
  .mainNav__item--logo {
    display: block;
  }
}
@media (min-width: 1180px) {
  .mainNav__item--logo {
    margin-inline-start: auto;
  }
}

.pageMain {
  padding-block-start: 75px;
}
@media (min-width: 940px) {
  .pageMain {
    padding-block-start: 80px;
  }
}

.social {
  display: flex;
  padding-inline-end: 15px;
  padding-inline-start: 15px;
  height: 40px;
  align-items: center;
}
@media (min-width: 940px) {
  .social {
    height: 80px;
    order: 1;
    padding-inline-end: 0;
  }
}

.social__item {
  width: 30px;
  height: 30px;
  padding: 4px;
  content: "";
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: transparent;
  margin-inline-end: 8px;
}

.social__item:last-child {
  margin-inline-end: 0;
}

.social__item:hover,
.social__item:focus {
  background-color: #ffffffcc;
}

.social__item:hover .social__icon--yt,
.social__item:focus .social__icon--yt {
  fill: #FF0000;
}

.social__item:hover .social__icon--fb,
.social__item:focus .social__icon--fb {
  fill: #4267B2;
}

.social__item:hover .social__icon--insta,
.social__item:focus .social__icon--insta {
  fill: #5B51D8;
}

.social__item--big {
  width: 60px;
  height: 60px;
  padding: 10px;
  background-color: #ffffffcc;
}

.social__item--big:hover,
.social__item--big:focus {
  background-color: #ffffff;
}

.social__item--big:not(:last-of-type) {
  margin-inline-end: 32px;
}
@media (min-width: 764px) {
  .social__item--big:not(:last-of-type) {
    margin-inline-end: 32px;
  }
}

.social__icon {
  width: 100%;
  height: 100%;
  fill: #111111;
}

@media (min-width: 940px) {
  .logo {
    width: 150px;
    height: 47px;
  }
}

.promo {
  display: grid;
  background-color: #000000;
  background-image: url("../img/main-promo-house.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  width: 100vw;
  min-height: calc(100vh - 75px);
  height: 100%;
}
@media (min-width: 940px) {
  .promo {
    min-height: calc(100vh - 80px);
  }
}

@media (min-width: 764px) {
  .promo--half {
    min-height: calc(60vh - 80px);
    background-position: center;
  }
}
@media (min-width: 940px) {
  .promo--half {
    min-height: calc(60vh - 80px);
  }
}

.promo__wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  margin-block-start: 60px;
  margin-block-end: 90px;
}
@media (min-width: 568px) {
  .promo__wrapper {
    margin-block-start: 40px;
    margin-block-end: 40px;
  }
}

.promo__heading {
  margin-block-end: 24px;
}
@media (min-width: 568px) {
  .promo__heading {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }
}
@media (min-width: 764px) {
  .promo__heading {
    margin-block-start: 30px;
    margin-block-end: 30px;
  }
}

.promo__text {
  text-align: center;
  margin-block-end: 60px;
}
@media (min-width: 568px) {
  .promo__text {
    margin-block-end: 40px;
  }
}
@media (min-width: 764px) {
  .promo__text {
    margin-block-end: 60px;
  }
}

.promo__buttons {
  display: flex;
  gap: 24px;
  margin-block-start: 16px;
}
@media (min-width: 764px) {
  .promo__buttons {
    margin-block-start: 120px;
  }
}

.content-slider {
  position: relative;
  overflow: hidden;
  margin-block-start: 40px;
  margin-block-end: 40px;
}
@media (min-width: 764px) {
  .content-slider {
    margin-block-start: 60px;
    margin-block-end: 60px;
  }
}
@media (min-width: 940px) {
  .content-slider {
    margin-block-start: 16px;
    margin-block-end: 16px;
  }
}

.content-slider--top {
  margin-block-start: 0;
}
@media (min-width: 764px) {
  .content-slider--top {
    margin-block-start: 0;
  }
}
@media (min-width: 940px) {
  .content-slider--top {
    margin-block-start: 0;
  }
}

.content-slider__wrapper {
  height: 100%;
  display: flex;
  align-items: stretch;
  transition: transform 0.6s ease;
}

@media (min-width: 940px) {
  .content-slider__wrapper--top {
    max-height: 60vh;
  }
}

.content-slider__item {
  flex: 0 0 100%;
  max-width: 100%;
}

.content-slider__item--houses {
  background-image: url("../img/projects-promo-houses.jpg");
}

.content-slider__item--saunas {
  background-image: url("../img/projects-promo-saunas.jpg");
}

.content-slider__item--projectsSmall {
  background-image: url("../img/projects-promo-saunas.jpg");
}

.content-slider__item--projects {
  background-image: url("../img/projects-promo-saunas.jpg");
}

.content-slider__item--projectsBig {
  background-image: url("../img/projects-promo-saunas.jpg");
}

.content-slider__text {
  align-content: flex-start;
}

.content-slider__control {
  position: absolute;
  bottom: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  z-index: 2;
}

.content-slider__control--quote {
  bottom: 75vw;
}
@media (min-width: 764px) {
  .content-slider__control--quote {
    bottom: 55vw;
  }
}
@media (min-width: 940px) {
  .content-slider__control--quote {
    bottom: 50%;
  }
}

.content-slider__control:hover,
.content-slider__control:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.content-slider__control-left {
  left: 20px;
}
@media (min-width: 764px) {
  .content-slider__control-left {
    left: 20px;
  }
}
@media (min-width: 1180px) {
  .content-slider__control-left {
    left: 50px;
  }
}

.content-slider__control-right {
  right: 20px;
}
@media (min-width: 764px) {
  .content-slider__control-right {
    right: 20px;
  }
}
@media (min-width: 1180px) {
  .content-slider__control-right {
    right: 50px;
  }
}

.content-slider__control::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.content-slider__control-left::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.content-slider__control-right::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.content-slider__bullets {
  position: absolute;
  top: 90%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  left: calc(50% - 90px);
}

.content-slider__bullet {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff33;
  cursor: pointer;
}

.content-slider__bullet-active {
  background-color: #ffffff;
}

.gallery {
  display: grid;
  width: 100%;
}
@media (min-width: 568px) {
  .gallery {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 940px) {
  .gallery {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.gallery--flex {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  max-height: 95%;
  margin-block-start: auto;
  margin-block-end: auto;
}
@media (min-width: 940px) {
  .gallery--flex {
    max-height: 75%;
  }
}

.gallery__img {
  width: 100%;
  display: block;
  height: auto;
  object-fit: cover;
  aspect-ratio: 4/3;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}

.gallery__img:hover,
.gallery__img:focus {
  transform: scale(1.02);
}

.gallery__img--view {
  width: auto;
  height: auto;
  max-width: 95%;
  max-height: 95%;
  cursor: default;
}
@media (min-width: 940px) {
  .gallery__img--view {
    max-width: 75%;
    max-height: 75%;
  }
}

.gallery__img--view:hover,
.gallery__img--view:focus {
  transform: none;
}

.grid {
  margin-block-start: 40px;
  margin-block-end: 40px;
}
@media (min-width: 764px) {
  .grid {
    margin-block-start: 60px;
    margin-block-end: 60px;
  }
}
@media (min-width: 940px) {
  .grid {
    margin-block-start: 90px;
    margin-block-end: 90px;
  }
}
@media (min-width: 1180px) {
  .grid {
    margin-block-start: 120px;
    margin-block-end: 120px;
  }
}

.grid--marginTop-0 {
  margin-block-start: 0;
}
@media (min-width: 764px) {
  .grid--marginTop-0 {
    margin-block-start: 0;
  }
}
@media (min-width: 940px) {
  .grid--marginTop-0 {
    margin-block-start: 0;
  }
}
@media (min-width: 1180px) {
  .grid--marginTop-0 {
    margin-block-start: 0;
  }
}

.grid__article {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  width: 100%;
  max-width: 290px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
@media (min-width: 568px) {
  .grid__article {
    grid-template-columns: 1fr 1fr;
    max-width: 546px;
  }
}
@media (min-width: 764px) {
  .grid__article {
    gap: 30px;
    max-width: 704px;
  }
}
@media (min-width: 940px) {
  .grid__article {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    max-width: 910px;
  }
}
@media (min-width: 1180px) {
  .grid__article {
    gap: 24px;
    max-width: 1120px;
  }
}

@media (min-width: 764px) {
  .grid__article--gap-big {
    gap: 30px;
  }
}
@media (min-width: 940px) {
  .grid__article--gap-big {
    gap: 40px;
  }
}
@media (min-width: 1180px) {
  .grid__article--gap-big {
    gap: 60px;
  }
}

@media (min-width: 764px) {
  .grid__article--three {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 940px) {
  .grid__article--three {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 764px) {
  .grid__article--two {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 940px) {
  .grid__article--two {
    grid-template-columns: 1fr 1fr;
  }
}

.grid__element {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.grid__element--marginBottom-medium {
  margin-block-end: 20px;
}
@media (min-width: 764px) {
  .grid__element--marginBottom-medium {
    margin-block-end: 40px;
  }
}

.grid__element--wide {
  grid-column-start: 1;
  grid-column-end: -1;
}

.img {
  overflow: hidden;
}

.img--preview {
  max-height: 240px;
  height: 100%;
  border-radius: 10px;
}

.img__design--preview {
  width: 280px;
  height: 280px;
  border-radius: 10px;
}
@media (min-width: 568px) {
  .img__design--preview {
    width: 220px;
    height: 220px;
  }
}

.img__img--team {
  width: 200px;
  height: 200px;
}

.img__img--project {
  filter: grayscale(1) opacity(0.9) drop-shadow(0 0 0 #a3b3c3);
}

.img__img--project:hover,
.img__img--project:focus {
  filter: grayscale(0);
}

.img--youtube {
  width: 228px;
  height: 160px;
  filter: drop-shadow(0px 4px 10px rgba(255, 0, 0, 0.25));
}

.img--youtube:hover,
.img--youtube:focus {
  filter: drop-shadow(0px 10px 10px rgba(255, 0, 0, 0.5));
}

.img--youtube:active {
  filter: drop-shadow(0px 4px 10px rgba(255, 0, 0, 0.5));
}

.img__img {
  width: 100%;
}

.img__img--team {
  width: 200px;
  height: 200px;
}

.hr {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(90deg, white 0%, white 15%, #888888 45%, #888888 55%, white 85%, white 100%);
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.list {
  max-width: 740px;
  margin-block-start: 0;
  margin-block-end: 0;
}
@media (min-width: 764px) {
  .list {
    padding-inline-start: 120px;
  }
}
@media (min-width: 940px) {
  .list {
    max-width: 740px;
  }
}

@media (min-width: 764px) {
  .list--paddingLeft-small {
    padding-inline-start: 40px;
  }
}

.list--ordered {
  list-style: none;
  counter-set: count;
}

.list__item {
  position: relative;
}

.list__item:not(:last-child) {
  margin-block-end: 40px;
}
@media (min-width: 764px) {
  .list__item:not(:last-child) {
    margin-block-end: 60px;
  }
}

.list__item--ordered {
  counter-increment: count;
}

.list__item--ordered::before {
  position: absolute;
  content: counter(count);
  width: 40px;
  height: 40px;
  left: -60px;
  border-radius: 50%;
  background-color: #333333;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 764px) {
  .list__item--ordered::before {
    padding-inline-end: 2px;
  }
}

.list__item--ordered:not(:last-child)::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: -40px;
  top: 40px;
  background: linear-gradient(180deg, #888888 0%, white 100%);
}

.preview {
  text-decoration: none;
  color: #333333;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: column;
}

.preview:hover,
.preview:focus {
  transform: scale(1.01);
}

.preview:hover .preview__img,
.preview:focus .preview__img {
  transform: scale(1.01);
}

.preview:hover .button--alt,
.preview:focus .button--alt {
  color: #000000;
  background-color: #00000010;
  box-shadow: 0px 3px 8px 0px rgba(51, 51, 51, 0.2);
  transform: translateY(-2px);
}

.preview:active .button--alt {
  color: #000000;
  background-color: #00000010;
  box-shadow: 0px 1px 6px 0px rgba(51, 51, 51, 0.2);
  transform: translateY(-1px);
}

.preview__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000000dd;
  z-index: 50;
  display: none;
  overflow: auto;
  overflow-x: scroll;
}

.overlay::-webkit-scrollbar:horizontal {
  height: 11px;
  appearance: slider-horizontal;
}

.overlay--visible {
  display: flex;
}

.quote {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  min-height: 50vh;
}
@media (min-width: 940px) {
  .quote {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.quote--house {
  background-color: transparent;
}

.quote__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  margin-block-start: 60px;
  margin-block-end: 90px;
  position: relative;
}
@media (min-width: 568px) {
  .quote__wrapper {
    margin-block-start: 40px;
  }
}
@media (min-width: 764px) {
  .quote__wrapper {
    margin-block-end: 120px;
    max-width: 75%;
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
}
@media (min-width: 940px) {
  .quote__wrapper {
    max-width: 60%;
  }
}
@media (min-width: 1180px) {
  .quote__wrapper {
    max-width: 480px;
    margin-inline-end: 40px;
  }
}

.quote__wrapper:not(.quote__wrapper--house):before,
.quote__wrapper:not(.quote__wrapper--house):after {
  position: absolute;
  content: "";
  width: 45px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
}

.quote__wrapper:not(.quote__wrapper--house):before {
  background-image: url("../img/icons/icon-tick-open.svg");
  top: -40px;
  left: 16px;
}
@media (min-width: 764px) {
  .quote__wrapper:not(.quote__wrapper--house):before {
    left: -40px;
    top: 40px;
  }
}
@media (min-width: 940px) {
  .quote__wrapper:not(.quote__wrapper--house):before {
    top: 80px;
  }
}

.quote__wrapper:not(.quote__wrapper--house):after {
  background-image: url("../img/icons/icon-tick-close.svg");
  bottom: -40px;
  right: 16px;
}
@media (min-width: 764px) {
  .quote__wrapper:not(.quote__wrapper--house):after {
    right: -40px;
  }
}
@media (min-width: 940px) {
  .quote__wrapper:not(.quote__wrapper--house):after {
    bottom: 0;
  }
}
@media (min-width: 1180px) {
  .quote__wrapper:not(.quote__wrapper--house):after {
    bottom: 40px;
  }
}

.quote__subHeading {
  font-size: 14px;
  line-height: 18px;
  margin-block-start: 0;
  opacity: 0.6;
}

.quote__videoWrapper {
  display: flex;
  margin-block-start: auto;
}
@media (min-width: 940px) {
  .quote__videoWrapper {
    max-width: 75%;
    height: 100%;
  }
}
@media (min-width: 1180px) {
  .quote__videoWrapper {
    max-width: 75%;
    max-width: 480px;
    height: 100%;
    margin-inline-start: 60px;
  }
}

.quote__video {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  background-color: #ffffff60;
}
@media (min-width: 764px) {
  .quote__video {
    aspect-ratio: 16/9;
  }
}
@media (min-width: 940px) {
  .quote__video {
    margin-block-start: auto;
    margin-block-end: auto;
    height: auto;
    border-radius: 5px;
  }
}

.button {
  border-radius: 7px;
  border: 1px solid #ffffff;
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  width: fit-content;
  height: 40px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  display: grid;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}

.button:hover,
.button:focus {
  color: #000000;
  background-color: #ffffff90;
  box-shadow: 0px 3px 8px 0px rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

.button:active {
  color: #000000;
  background-color: #ffffff60;
  box-shadow: 0px 1px 6px 0px rgba(255, 255, 255, 0.3);
  transform: translateY(-1px);
}

.button--uppercase {
  text-transform: uppercase;
}

.button--bold {
  font-weight: bold;
}

.button--big {
  min-height: 60px;
  min-width: 120px;
}

.button--alt {
  border: 1px solid #00000040;
  color: #333333;
}

.button--alt:hover,
.button--alt:focus {
  color: #000000;
  background-color: #00000010;
  box-shadow: 0px 3px 8px 0px rgba(51, 51, 51, 0.2);
  transform: translateY(-2px);
}

.button--alt:active {
  color: #000000;
  background-color: #00000010;
  box-shadow: 0px 1px 6px 0px rgba(51, 51, 51, 0.2);
  transform: translateY(-1px);
}

.button--marginTop-15 {
  margin-block-start: 15px;
}

.button--marginBottom-20 {
  margin-block-end: 20px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

.form--wide {
  max-width: 460px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
@media (min-width: 940px) {
  .form--wide {
    max-width: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.form__label {
  width: 100%;
  position: relative;
  padding-block-start: 16px;
}

.form__input {
  display: block;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ffffff90;
  width: 100%;
  max-width: 460px;
  font-size: 18px;
  line-height: 28px;
  padding-inline-end: 10px;
  padding-inline-start: 10px;
  padding-block-end: 10px;
  padding-block-start: 10px;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  color: #ffffff;
  cursor: pointer;
}

.form__input:focus {
  border-bottom: 1px solid #ffffff;
  outline: none;
}

.form__input:valid {
  border-bottom: 1px solid #ffffff;
}

.form__input--dark {
  color: #333333;
  border-bottom: 1px solid #00000090;
}

.form__input--dark:focus {
  border-bottom: 1px solid #333333;
  outline: none;
}

.form__input--dark:valid {
  border-bottom: 1px solid #333333;
}

.form__placeholder {
  position: absolute;
  top: 40%;
  left: 0;
  color: #ffffff90;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  pointer-events: none;
}

.form__placeholder--dark {
  color: #00000090;
}

.form__placeholder--top {
  top: 0;
  font-size: 0.7em;
}

.form__input:focus ~ .form__placeholder {
  top: 0;
  font-size: 0.7em;
  color: #ffffff;
}

.form__input--dark:focus ~ .form__placeholder {
  color: #333333;
}

.form__submit {
  color: #111111;
}

.form__submit--dark {
  color: #ffffff;
  background-color: #111111;
}

.form__submit--dark {
  color: #ffffff;
  background-color: #111111;
}

.form__submit--dark:hover,
.form__submit--dark:focus {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5);
}

.form__submit--dark:active {
  background-color: #333333;
  border-color: #333333;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.5);
}

.form__submit--wide {
  padding-inline-start: 40px;
  padding-inline-end: 40px;
  grid-column-start: 1;
  grid-column-end: -1;
}

.form__submit--center {
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.tabGroups {
  overflow-x: hidden;
  width: 100%;
}
.tabGroups--marginTop-0 {
  margin-block-start: 0;
}
@media (min-width: 764px) {
  .tabGroups--marginTop-0 {
    margin-block-start: 0;
  }
}
@media (min-width: 940px) {
  .tabGroups--marginTop-0 {
    margin-block-start: 0;
  }
}

.tabGroups--marginBottom-0 {
  margin-block-end: 0;
}
@media (min-width: 764px) {
  .tabGroups--marginBottom-0 {
    margin-block-end: 0;
  }
}
@media (min-width: 940px) {
  .tabGroups--marginBottom-0 {
    margin-block-end: 0;
  }
}

.tabGroups__tabInput {
  display: none;
}

.tabGroups__tabs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: auto;
  min-height: 43px;
}
.tabGroups__tabs--desktop {
  display: none;
}
@media (min-width: 940px) {
  .tabGroups__tabs--desktop {
    display: flex;
  }
}

.tabGroups__select {
  width: 280px;
  color: #333333;
  background-color: #00000010;
  border: 2px solid #00000010;
  border-radius: 13px;
  padding: 15px;
  margin-inline-end: auto;
  appearance: none;
  background-image: url(../img/icons/icon-drop-down.svg);
  background-repeat: no-repeat;
  background-position: 94% 50%;
  background-size: 15px 15px;
}
@media (min-width: 940px) {
  .tabGroups__select {
    display: none;
  }
}

.tabGroups__select:focus {
  outline: none;
  border: 2px solid #888888;
}

.tabGroups__label {
  min-height: 36px;
  color: #333333;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 30px;
  padding-block-start: 10px;
  padding-block-end: 10px;
  cursor: pointer;
  background-image: linear-gradient(to right, #ffffff00 100%, #ffffff00 100%);
  background-position: 0 1.4em;
  background-repeat: repeat-x;
  background-size: 8px 2px;
}
@media (min-width: 764px) {
  .tabGroups__label {
    font-size: 20px;
  }
}

.tabGroups__label:last-child {
  margin-inline-end: 0;
}

.tabGroups__label--checked {
  background-image: linear-gradient(to right, #2e75e680 100%, #2e75e680 100%);
}

.tabGroups__label:hover {
  background-color: #00000010;
}

.tabGroups__label:focus,
.tabGroups__label:active {
  background-color: #00000010;
}

#tab1:checked ~ .tabGroups__tabs #labelTab1,
#tab2:checked ~ .tabGroups__tabs #labelTab2,
#tab3:checked ~ .tabGroups__tabs #labelTab3,
#tab4:checked ~ .tabGroups__tabs #labelTab4,
#tab5:checked ~ .tabGroups__tabs #labelTab5 {
  border: 1px solid #333333;
  background-color: #00000010;
}

#tab1:checked ~ .tabGroups__tabs--alt #labelTab1,
#tab2:checked ~ .tabGroups__tabs--alt #labelTab2,
#tab3:checked ~ .tabGroups__tabs--alt #labelTab3,
#tab4:checked ~ .tabGroups__tabs--alt #labelTab4,
#tab5:checked ~ .tabGroups__tabs--alt #labelTab5 {
  background-color: #00000010;
}

.tabGroups__content {
  display: none;
  overflow-x: auto;
  padding-block-start: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
@media (min-width: 764px) {
  .tabGroups__content {
    justify-content: center;
  }
}
.tabGroups__content--noPaddingBottom {
  padding-block-end: 0;
}
@media (min-width: 568px) {
  .tabGroups__content--noPaddingBottom {
    padding-block-end: 0;
  }
}

.tabGroups__content--four {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media (min-width: 764px) {
  .tabGroups__content--four {
    justify-content: normal;
  }
}
@media (min-width: 940px) {
  .tabGroups__content--four {
    justify-content: center;
  }
}

.tabGroups__content--grid-one {
  grid-template-columns: 1fr;
}

.tabGroups__content--display-flex {
  display: flex;
}

.tabGroups__content--display-grid {
  display: grid;
}

.tabGroups__content::-webkit-scrollbar {
  display: none;
}

.tabGroups__content--flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 940px) {
  .tabGroups__content--flex {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.pageFooter {
  width: 100%;
  display: grid;
  min-height: calc(100vh - 70px);
}
@media (min-width: 764px) {
  .pageFooter {
    grid-template-columns: 1fr 1fr;
    min-height: calc(70vh - 70px);
  }
}
@media (min-width: 940px) {
  .pageFooter {
    grid-template-columns: 1fr 1fr;
    min-height: calc(80vh - 80px);
  }
}

.pageFooter__map {
  width: 100vw;
  height: 100%;
  min-height: 300px;
}
@media (min-width: 764px) {
  .pageFooter__map {
    width: 100%;
  }
}

.pageFooter__right {
  width: 100vw;
  background-color: #111111;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  padding-block-start: 40px;
  padding-block-end: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 764px) {
  .pageFooter__right {
    width: 100%;
    padding-inline-start: 30px;
    padding-inline-end: 30px;
    padding-block-end: 40px;
  }
}
@media (min-width: 940px) {
  .pageFooter__right {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }
}

.pageFooter__social {
  padding-inline-start: 0;
  min-height: 60px;
}

@font-face {
  font-family: "lg";
  src: url("../fonts/lightgallery/lg.woff2?io9a6k") format("woff2"), url("../fonts/lightgallery/lg.ttf?io9a6k") format("truetype"), url("../fonts/lightgallery/lg.woff?io9a6k") format("woff"), url("../fonts/lightgallery/lg.svg?io9a6k#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-container {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.lg-next,
.lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1084;
  outline: none;
  border: none;
}
.lg-next.disabled,
.lg-prev.disabled {
  opacity: 0 !important;
  cursor: default;
}
.lg-next:hover:not(.disabled),
.lg-prev:hover:not(.disabled) {
  color: #fff;
}
.lg-single-item .lg-next,
.lg-single-item .lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}
.lg-next:before {
  content: "\e095";
}

.lg-prev {
  left: 20px;
}
.lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}
.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  will-change: color;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  background: none;
  border: none;
  box-shadow: none;
}
.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}
.lg-toolbar .lg-icon:hover {
  color: #fff;
}
.lg-toolbar .lg-close:after {
  content: "\e070";
}
.lg-toolbar .lg-maximize {
  font-size: 22px;
}
.lg-toolbar .lg-maximize:after {
  content: "\e90a";
}
.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  color: #eee;
  font-size: 16px;
  padding: 10px 40px;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out 0s;
  -o-transition: opacity 0.2s ease-out 0s;
  transition: opacity 0.2s ease-out 0s;
}
.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}
.lg-sub-html a {
  color: inherit;
}
.lg-sub-html a:hover {
  text-decoration: underline;
}
.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}
.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.lg-error-msg {
  font-size: 14px;
  color: #999;
}

.lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  height: 47px;
  vertical-align: middle;
}

.lg-closing .lg-toolbar,
.lg-closing .lg-prev,
.lg-closing .lg-next,
.lg-closing .lg-sub-html {
  opacity: 0;
  -webkit-transition: -webkit-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -moz-transition: -moz-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -o-transition: -o-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  transition: transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  -moz-transform: scale3d(0.5, 0.5, 0.5);
  -o-transform: scale3d(0.5, 0.5, 0.5);
  -ms-transform: scale3d(0.5, 0.5, 0.5);
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  -moz-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: 3px;
  outline: 1px dashed rgba(255, 255, 255, 0.6);
}

.lg-toolbar .lg-icon:focus-visible {
  border-radius: 8px;
  outline-offset: -5px;
}

.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  width: 100%;
  max-height: 350px;
  overflow: hidden;
  float: left;
}
.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-outer .lg-thumb-outer.lg-rebuilding-thumbnails .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-outer .lg-thumb-outer.lg-thumb-align-middle {
  text-align: center;
}
.lg-outer .lg-thumb-outer.lg-thumb-align-left {
  text-align: left;
}
.lg-outer .lg-thumb-outer.lg-thumb-align-right {
  text-align: right;
}
.lg-outer.lg-single-item .lg-thumb-outer {
  display: none;
}
.lg-outer .lg-thumb {
  padding: 5px 0;
  height: 100%;
  margin-bottom: -5px;
  display: inline-block;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .lg-outer .lg-thumb {
    padding: 10px 0;
  }
}
.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border-radius: 2px;
  margin-bottom: 5px;
  will-change: border-color;
}
@media (min-width: 768px) {
  .lg-outer .lg-thumb-item {
    border-radius: 4px;
    border: 2px solid #fff;
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}
.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}
.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff";
}
.lg-outer.lg-animate-thumb .lg-thumb {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-container {
  display: none;
  outline: none;
}
.lg-container.lg-show {
  display: block;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-overlay-open {
  overflow: hidden;
}

.lg-toolbar,
.lg-prev,
.lg-next,
.lg-pager-outer,
.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-show-in .lg-toolbar,
.lg-show-in .lg-prev,
.lg-show-in .lg-next,
.lg-show-in .lg-pager-outer {
  opacity: 1;
}
.lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}
.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}
.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}
.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}
.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0.001;
  outline: none;
  will-change: auto;
  overflow: hidden;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-zoom-from-image {
  opacity: 1;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.lg-outer .lg-inner {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: opacity 0s;
  -o-transition: opacity 0s;
  transition: opacity 0s;
  white-space: nowrap;
}
.lg-outer .lg-item {
  display: none !important;
}
.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-object {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative;
}
.lg-outer .lg-empty-html.lg-sub-html,
.lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}
.lg-outer.lg-hide-download .lg-download {
  opacity: 0.75;
  pointer-events: none;
}
.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
  opacity: 1;
}
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.15s;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  will-change: auto;
  -webkit-transition: opacity 333ms ease-in 0s;
  -o-transition: opacity 333ms ease-in 0s;
  transition: opacity 333ms ease-in 0s;
}
.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-container {
  display: none;
}
.lg-container.lg-show {
  display: block;
}
.lg-container.lg-dragging-vertical .lg-backdrop {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-inline .lg-backdrop,
.lg-inline .lg-outer {
  position: absolute;
}
.lg-inline .lg-backdrop {
  z-index: 1;
}
.lg-inline .lg-outer {
  z-index: 2;
}
.lg-inline .lg-maximize:after {
  content: "\e909";
}

.lg-components {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  will-change: transform;
  -webkit-transition: -webkit-transform 0.35s ease-out 0s;
  -moz-transition: -moz-transform 0.35s ease-out 0s;
  -o-transition: -o-transform 0.35s ease-out 0s;
  transition: transform 0.35s ease-out 0s;
  z-index: 1080;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

@font-face {
  font-family: "lg";
  src: url("../fonts/lightgallery/lg.woff2?io9a6k") format("woff2"), url("../fonts/lightgallery/lg.ttf?io9a6k") format("truetype"), url("../fonts/lightgallery/lg.woff?io9a6k") format("woff"), url("../fonts/lightgallery/lg.svg?io9a6k#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-container {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.lg-next,
.lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1084;
  outline: none;
  border: none;
}
.lg-next.disabled,
.lg-prev.disabled {
  opacity: 0 !important;
  cursor: default;
}
.lg-next:hover:not(.disabled),
.lg-prev:hover:not(.disabled) {
  color: #fff;
}
.lg-single-item .lg-next,
.lg-single-item .lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}
.lg-next:before {
  content: "\e095";
}

.lg-prev {
  left: 20px;
}
.lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}
.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  will-change: color;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  background: none;
  border: none;
  box-shadow: none;
}
.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}
.lg-toolbar .lg-icon:hover {
  color: #fff;
}
.lg-toolbar .lg-close:after {
  content: "\e070";
}
.lg-toolbar .lg-maximize {
  font-size: 22px;
}
.lg-toolbar .lg-maximize:after {
  content: "\e90a";
}
.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  color: #eee;
  font-size: 16px;
  padding: 10px 40px;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out 0s;
  -o-transition: opacity 0.2s ease-out 0s;
  transition: opacity 0.2s ease-out 0s;
}
.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}
.lg-sub-html a {
  color: inherit;
}
.lg-sub-html a:hover {
  text-decoration: underline;
}
.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}
.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.lg-error-msg {
  font-size: 14px;
  color: #999;
}

.lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  height: 47px;
  vertical-align: middle;
}

.lg-closing .lg-toolbar,
.lg-closing .lg-prev,
.lg-closing .lg-next,
.lg-closing .lg-sub-html {
  opacity: 0;
  -webkit-transition: -webkit-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -moz-transition: -moz-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -o-transition: -o-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  transition: transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  -moz-transform: scale3d(0.5, 0.5, 0.5);
  -o-transform: scale3d(0.5, 0.5, 0.5);
  -ms-transform: scale3d(0.5, 0.5, 0.5);
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  -moz-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: 3px;
  outline: 1px dashed rgba(255, 255, 255, 0.6);
}

.lg-toolbar .lg-icon:focus-visible {
  border-radius: 8px;
  outline-offset: -5px;
}

.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-container {
  display: none;
  outline: none;
}
.lg-container.lg-show {
  display: block;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-overlay-open {
  overflow: hidden;
}

.lg-toolbar,
.lg-prev,
.lg-next,
.lg-pager-outer,
.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-show-in .lg-toolbar,
.lg-show-in .lg-prev,
.lg-show-in .lg-next,
.lg-show-in .lg-pager-outer {
  opacity: 1;
}
.lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}
.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}
.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}
.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}
.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0.001;
  outline: none;
  will-change: auto;
  overflow: hidden;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-zoom-from-image {
  opacity: 1;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.lg-outer .lg-inner {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: opacity 0s;
  -o-transition: opacity 0s;
  transition: opacity 0s;
  white-space: nowrap;
}
.lg-outer .lg-item {
  display: none !important;
}
.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-object {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative;
}
.lg-outer .lg-empty-html.lg-sub-html,
.lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}
.lg-outer.lg-hide-download .lg-download {
  opacity: 0.75;
  pointer-events: none;
}
.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
  opacity: 1;
}
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.15s;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  will-change: auto;
  -webkit-transition: opacity 333ms ease-in 0s;
  -o-transition: opacity 333ms ease-in 0s;
  transition: opacity 333ms ease-in 0s;
}
.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-container {
  display: none;
}
.lg-container.lg-show {
  display: block;
}
.lg-container.lg-dragging-vertical .lg-backdrop {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-inline .lg-backdrop,
.lg-inline .lg-outer {
  position: absolute;
}
.lg-inline .lg-backdrop {
  z-index: 1;
}
.lg-inline .lg-outer {
  z-index: 2;
}
.lg-inline .lg-maximize:after {
  content: "\e909";
}

.lg-components {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  will-change: transform;
  -webkit-transition: -webkit-transform 0.35s ease-out 0s;
  -moz-transition: -moz-transform 0.35s ease-out 0s;
  -o-transition: -o-transform 0.35s ease-out 0s;
  transition: transform 0.35s ease-out 0s;
  z-index: 1080;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}