.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $gap-2-mobile;
  align-items: flex-start;
}

.form--wide {
  max-width: 460px;
  margin-inline-start: auto;
  margin-inline-end: auto;

  @media (min-width: $width-tablet-album) {
    max-width: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.form__label {
  width: 100%;
  position: relative;
  padding-block-start: $gap-2-mobile;
}

.form__input {
  display: block;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $base-white-alpha-9;
  width: 100%;
  max-width: 460px;
  font-size: $text-mobile-font;
  line-height: $text-mobile-line;
  padding-inline-end: 10px;
  padding-inline-start: 10px;
  padding-block-end: 10px;
  padding-block-start: 10px;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: .2s;
  color: $base-white;
  cursor: pointer;
}

.form__input:focus {
  border-bottom: 1px solid $base-white;
  outline: none;
}

.form__input:valid {
  border-bottom: 1px solid $base-white;
}

.form__input--dark {
  color: $base-dark;
  border-bottom: 1px solid $base-black-alpha-9;
}

.form__input--dark:focus {
  border-bottom: 1px solid $base-dark;
  outline: none;
}

.form__input--dark:valid {
  border-bottom: 1px solid $base-dark;
}

.form__placeholder {
  position: absolute;
  top: 40%;
  left: 0;
  color: $base-white-alpha-9;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: .2s;
  pointer-events: none;
}

.form__placeholder--dark {
  color: $base-black-alpha-9;
}

.form__placeholder--top {
  top: 0;
  font-size: .7em;
}

.form__input:focus ~ .form__placeholder {
  top: 0;
  font-size: .7em;
  color: $base-white;
}

.form__input--dark:focus ~ .form__placeholder {
  color: $base-dark;
}

.form__submit {
  color: $base-darker;
}

.form__submit--dark {
  color: $base-white;
  background-color: $base-darker;
}

.form__submit--dark {
  color: $base-white;
  background-color: $base-darker;
}

.form__submit--dark:hover,
.form__submit--dark:focus {
  color: $base-white;
  background-color: $base-dark;
  border-color: $base-dark;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5);
}

.form__submit--dark:active {
  background-color: $base-dark;
  border-color: $base-dark;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.5);
}

.form__submit--wide {
  padding-inline-start: 40px;
  padding-inline-end: 40px;
  grid-column-start: 1;
  grid-column-end: -1;
}

.form__submit--center {
  margin-inline-start: auto;
  margin-inline-end: auto;
}