.preview {
  text-decoration: none;
  color: $base-dark;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: column;
}

.preview:hover,
.preview:focus {
  transform: scale(1.01);
}

.preview:hover .preview__img,
.preview:focus .preview__img {
  transform: scale(1.01);
}

.preview:hover .button--alt,
.preview:focus .button--alt {
  color: $base-black;
  background-color: $base-black-alpha-10;
  box-shadow: 0px 3px 8px 0px rgba(51, 51, 51, 0.2);
  transform: translateY(-2px);
}

.preview:active .button--alt {
  color: $base-black;
  background-color: $base-black-alpha-10;
  box-shadow: 0px 1px 6px 0px rgba(51, 51, 51, 0.2);
  transform: translateY(-1px);
}

.preview__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}