.pageFooter {
  width: 100%;
  display: grid;
  min-height: calc(100vh - 70px);
  
  @media (min-width: $width-tablet) {
    grid-template-columns: 1fr 1fr;
    min-height: calc(70vh - 70px);
  }
  
  @media (min-width: $width-tablet-album) {
    grid-template-columns: 1fr 1fr;
    min-height: calc(80vh - 80px);
  }
}

.pageFooter__map {
  width: 100vw;
  height: 100%;
  min-height: 300px;
  
  @media (min-width: $width-tablet) {
    width: 100%;
  }
}

.pageFooter__right {
  width: 100vw;
  background-color: $base-darker;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  padding-block-start: 40px;
  padding-block-end: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $width-tablet) {
    width: 100%;
    // margin-inline-end: 32px;
    padding-inline-start: 30px;
    padding-inline-end: 30px;
    padding-block-end: 40px;
  }

  @media (min-width: $width-tablet-album) {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }
}

.pageFooter__social {
  padding-inline-start: 0;
  min-height: 60px;
}