.gallery {
  display: grid;
  width: 100%;

  @media (min-width: $width-mobile-album) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $width-tablet-album) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.gallery--flex {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  max-height: 95%;
  margin-block-start: auto;
  margin-block-end: auto;

  @media (min-width: $width-tablet-album) {
    max-height: 75%;
  }
}

.gallery__link {
  
}

// .lg-thumb-img {
//   max-width: 100px;
//   max-height: 80px;
// }

.gallery__img {
  width: 100%;
  display: block;
  height: auto;
  object-fit: cover;
  aspect-ratio: 4 / 3;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}

.gallery__img:hover,
.gallery__img:focus {
  transform: scale(1.02);
}

.gallery__img--view {
  width: auto;
  height: auto;
  max-width: 95%;
  max-height: 95%;
  cursor: default;

  @media (min-width: $width-tablet-album) {
    max-width: 75%;
    max-height: 75%;
  }
}

.gallery__img--view:hover,
.gallery__img--view:focus {
  transform: none;
}