.promo {
  display: grid;
  background-color: $base-black;
  background-image: url('../img/main-promo-house.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  width: 100vw;
  min-height: calc(100vh - 75px);
  height: 100%;

  @media (min-width: $width-tablet-album) {
    min-height: calc(100vh - 80px);
  }
}

.promo--half {

  @media (min-width: $width-tablet) {
    min-height: calc(60vh - 80px);
    background-position: center;
  }

  @media (min-width: $width-tablet-album) {
    min-height: calc(60vh - 80px);
      
  }
}

.promo__wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  text-align: center;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: $padding-mobile;
  padding-inline-end: $padding-mobile;
  margin-block-start: $break-small;
  margin-block-end: $break;

  @media (min-width: $width-mobile-album) {
    margin-block-start: $break-x-small;
    margin-block-end: $break-x-small;
  }
}

.promo__heading {
  margin-block-end: $gap-3-mobile;
  
  @media (min-width: $width-mobile-album) {
    margin-block-start: $gap-mobile;
    margin-block-end: $gap-mobile;
  }

  @media (min-width: $width-tablet) {
    margin-block-start: $gap-tablet;
    margin-block-end: $gap-tablet;
  }
}

.promo__text {
  text-align: center;
  margin-block-end: $break-small;

  @media (min-width: $width-mobile-album) {
    margin-block-end: $break-x-small;
  }

  @media (min-width: $width-tablet) {
    margin-block-end: $gap-2-tablet;
  }
}

.promo__buttons {
  display: flex;
  gap: $gap-3-mobile;
  margin-block-start: $gap-2-mobile;  

  @media (min-width: $width-tablet) {
    margin-block-start: $break-big;
  }
}
